import request from '@/utils/request'

// 获取记录列表
export function list(data) {
  return request({
    url: 'UseWordLibrary/List',
    data,
    method: 'POST'
  })
}

// 删除记录
export function deWord(data) {
  return request({
    url: 'UseWordLibrary/DeWord',
    params: data,
    method: 'GET'
  })
}

// 查词统计
export function statistics(data) {
  return request({
    url: 'UseWordLibrary/Statistics',
    data,
    method: 'POST'
  })
}

// 是否已收藏
export function isCollect(data) {
  return request({
    url: 'UseWordLibrary/IsCollect',
    params: data,
    method: 'GET'
  })
}

// 查词统计
export function playLog(data) {
  return request({
    url: 'UseWordLibrary/PlayLog',
    data,
    method: 'POST'
  })
}

// 添加挖空记录
export function addHollow(data) {
  return request({
    url: 'UseWordLibrary/AddHollow',
    params: data,
    method: 'GET'
  })
}

// 删除挖空记录
export function deHollow(data) {
  return request({
    url: 'UseWordLibrary/DeHollow',
    params: data,
    method: 'GET'
  })
}

// 挖空记录列表
export function hollowList(data) {
  return request({
    url: 'UseWordLibrary/HollowList',
    params: data,
    method: 'GET'
  })
}
